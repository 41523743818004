<template>
  <Dashcard
    :loading="loading"
    :card="card"
    @expand-card="expand"
    @delete-card="$emit('delete-card', card)"
  >
    <template v-slot:today>
      <v-card v-if="stats.today.length" flat height="150">
        <v-card-text class="px-1">
          <CustomTimeline
            :items="stats.today"
            :display="display"
          ></CustomTimeline>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No activities today
        </h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card v-if="stats.week.length" flat height="150">
        <v-card-text class="px-1">
          <CustomTimeline
            :items="stats.week"
            :display="display"
          ></CustomTimeline>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No activities for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card v-if="stats.month.length" flat height="150">
        <v-card-text class="px-1">
          <CustomTimeline
            :items="stats.month"
            :display="display"
          ></CustomTimeline>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No activities for this month
        </h3>
      </v-card>
    </template>
  </Dashcard>
</template>
 

<script>
import { mapGetters } from "vuex";
import Dashcard from "../components/Dashcard.vue";
import CustomTimeline from "../components/CustomTimeline.vue";
import request from "@/services/axios_instance";
//Components
export default {
  name: "TimelineCard",
  props: {
    card: { type: Object },
  },
  data: () => ({
    loading: false,
    stats: {
      today: [],
      week: [],
      month: [],
    },
  }),
  created() {
    this.getData();
  },
  components: {
    Dashcard,
    CustomTimeline,
  },
  computed: {
    ...mapGetters("dashitems", ["grid_count"]),
    display() {
      return this.mdAndUp ? (Number(this.grid_count) === 3 ? 4 : 3) : 4;
    },
  },
  methods: {
    getData() {
      this.loading = true;
      request
        .get(`api/dashboard-contents/timeline`)
        .then(({ data }) => {
          this.stats = data;
        })
        .finally(() => (this.loading = false));
    },
    expand() {
      this.$router.push({ name: "expanded-timeline" });
    },
  },
};
</script>

<style lang="scss" scoped>
 
</style>