<template>
  <Dashcard
    :card="card"
    :loading="loading"
    @expand-card="expand"
    @delete-card="$emit('delete-card', card)"
  >
    <template v-slot:today>
      <v-card flat v-if="stats.today.paid || stats.today.pending">
        <v-card-text class="d-flex align-items-center justify-content-center">
          <h1
            class="
              primary--text
              text-center
              fs-30
              font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.today.paid }}
            <span class="caption d-block mt-3">Paid</span>
          </h1>
          <apexchart
            height="150"
            width="130"
            type="donut"
            :options="chartOptions"
            :series="today_series"
          ></apexchart>
          <h1
            class="
              primary--text
              text-center
              fs-30
              font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.today.pending }}
            <span class="caption d-block mt-3">Pending</span>
          </h1>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No invoices due today
        </h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card flat v-if="stats.week.paid || stats.week.pending">
        <v-card-text class="d-flex align-items-center justify-content-center">
          <h1
            class="
              primary--text
              text-center
              fs-30
              font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.week.paid }}
            <span class="caption d-block mt-3">Paid</span>
          </h1>
          <apexchart
            height="150"
            width="130"
            type="donut"
            :options="chartOptions"
            :series="week_series"
          ></apexchart>
          <h1
            class="
              primary--text
              text-center
              fs-30
              font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.week.pending }}
            <span class="caption d-block mt-3">Pending</span>
          </h1>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No invoices due for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card flat v-if="stats.month.paid || stats.month.pending">
        <v-card-text class="d-flex align-items-center justify-content-center">
          <h1
            class="
              primary--text
              text-center
              fs-30
              font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.month.paid }}
            <span class="caption d-block mt-3">Paid</span>
          </h1>
          <apexchart
            height="150"
            width="130"
            type="donut"
            :options="chartOptions"
            :series="month_series"
          ></apexchart>
          <h1
            class="
              primary--text
              text-center
              fs-30
              font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.month.pending }}
            <span class="caption d-block mt-3">Pending</span>
          </h1>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No invoices due for this month
        </h3>
      </v-card>
    </template>
    <template v-slot:above-image>
      <h5 class="caption font-weight-bold text-center primary--text">
        Total Paid
      </h5>
      <h2 class="font-weight-bold text-center primary--text">
        {{ stats.total_paid | money }}
      </h2>
    </template>
  </Dashcard>
</template>

<script>
import { mapGetters } from 'vuex'
import Dashcard from '../components/Dashcard.vue'
import ApexCharts from 'apexcharts'
import request from '@/services/axios_instance'
export default {
  name: 'InvoiceCard',
  props: {
    card: { type: Object }
  },
  components: {
    Dashcard,
    ApexCharts
  },
  data: () => ({
    loading: false,
    chartOptions: {
      labels: ['Paid Invoices', 'Pending Invoices'],
      legend: {
        show: false
      },
      dataLabels: {
        enabled: false
      },
      colors: ['#009788', '#2196f3'],
      plotOptions: {
        pie: {
          donut: {
            label: {
              show: true
            },
            size: '65%'
          }
        }
      }
    },
    stats: {
      today: {
        paid: 0,
        pending: 0
      },
      week: {
        paid: 0,
        pending: 0
      },
      month: {
        paid: 0,
        pending: 0
      },
      total_paid: 0
    }
  }),
  computed: {
    ...mapGetters('dashitems', ['grid_count']),
    today_series() {
      return [this.stats.today.paid, this.stats.today.pending]
    },
    week_series() {
      return [this.stats.week.paid, this.stats.week.pending]
    },
    month_series() {
      return [this.stats.month.paid, this.stats.month.pending]
    }
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loading = true
      request
        .get(`api/dashboard-contents/invoices`)
        .then(({ data }) => {
          this.stats = data
        })
        .finally(() => (this.loading = false))
    },
    expand() {
      this.$router.push({ name: 'expanded-invoices' })
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
  width: 100%;
  overflow: hidden;
  height: 300px;
  background-position-x: 90%;
  background-position-y: 100%;
  background-size: 75px 100px;
  background-repeat: no-repeat;
  background-image: url('~@/assets/info.png');
}
.ytoolbar {
  border-bottom: 1px solid grey !important;
}
</style>
