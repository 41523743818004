<template>
  <div class="dashboard-content">
    <v-row
      v-if="mdAndUp"
      dense
      class="fill-height flex-nowrap mb-5"
      justify="space-between"
      align="center"
    >
      <template v-for="(tile, index) in dashtiles">
        <v-col class="col" :key="index">
          <Dashtile
            :value="tile.value"
            :label="tile.title"
            :icon="tile.icon"
            @navigate="$router.push(tile.route)"
          ></Dashtile>
        </v-col>
      </template>
      <v-btn-toggle
        v-model="grid_count"
        mandatory
        class="mr-1 btn-grid-count-toggle"
      >
        <v-btn
          :color="grid_count === 2 ? 'primary' : ''"
          @click.stop="grid_count = 2"
          :dark="grid_count === 2"
          :value="2"
        >
          <v-icon :color="grid_count === 2 ? '#fff' : 'primary'">
            mdi-view-grid
          </v-icon>
        </v-btn>
        <v-btn
          :color="grid_count === 3 ? 'primary' : ''"
          @click.stop="grid_count = 3"
          :dark="grid_count === 3"
          :value="3"
        >
          <v-icon :color="grid_count === 3 ? '#fff' : 'primary'">
            mdi-view-module
          </v-icon>
        </v-btn>
      </v-btn-toggle>
    </v-row>
    <v-row dense v-if="mycards.length">
      <draggable
        class="row d__cards"
        force-fallback="true"
        scroll-sensitivity="500"
        v-model="mycards"
        draggable=".draggable-item"
      >
        <template v-for="(card, index) in mycards">
          <v-col :cols="card_per_row" :key="index" class="draggable-item">
            <component
              :card="card"
              :is="card.component"
              :key="`card-${index}`"
              @delete-card="handleDelete"
            ></component>
          </v-col>
        </template>
        <v-col :cols="card_per_row" v-if="has_more_cards">
          <v-card elevation="3" min-height="300" class="rounded-10 ma-2">
            <v-card-text class="d-flex fill-height">
              <v-row
                dense
                class="align-self-center"
                align="center"
                justify="center"
              >
                <empty icon="mdi-view-grid-plus" headline="">
                  <template v-slot:extra>
                    <v-btn
                      height="50"
                      block
                      color="primary"
                      dark
                      class="text-none px-5"
                      @click.stop="openCardSidebar"
                    >
                      <span> Add dashcard </span> <v-spacer />
                      <v-icon right> mdi-plus-circle-outline </v-icon>
                    </v-btn>
                  </template>
                </empty>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </draggable>
    </v-row>
    <empty
      v-else
      style="min-height: 500px"
      icon="mdi-view-grid-plus"
      headline="No selected cards"
    >
      <template v-slot:extra>
        <v-btn
          height="50"
          block
          color="primary"
          dark
          class="text-none px-5"
          @click.stop="openCardSidebar"
        >
          <span> Add dashcard </span> <v-spacer />
          <v-icon right> mdi-plus-circle-outline </v-icon>
        </v-btn>
      </template>
    </empty>
    <DeleteDialog
      v-model="deleteDialog"
      title="Remove card confirmation"
      text-content="Remove this card? <br><span class='caption'>You can still put it back later</span>"
      delete-button-text="Yes"
      @delete="confirmedDelete"
    ></DeleteDialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import Dashtile from './components/Dashtile.vue'
import TimelineCard from './Cards/TimelineCard.vue'
import AlarmCard from './Cards/AlarmCard.vue'
import ClientCard from './Cards/ClientCard.vue'
import InvoiceCard from './Cards/InvoiceCard.vue'
import CalendarCard from './Cards/CalendarCard.vue'
import TaskCard from './Cards/TaskCard.vue'
import TimerCard from './Cards/TimerCard.vue'
import DeleteDialog from '../../common/DeleteDialog.vue'
import Dashcard from './components/Dashcard.vue'
import draggable from 'vuedraggable'
export default {
  components: {
    Dashtile,
    TimelineCard,
    AlarmCard,
    ClientCard,
    InvoiceCard,
    CalendarCard,
    TaskCard,
    TimerCard,
    DeleteDialog,
    Dashcard,
    draggable
  },
  name: 'DashboardContent',
  props: {
    id: [Number, String]
  },
  data: () => ({
    activeCard: null,
    deleteDialog: false
  }),
  created() {
    this.init_userCards()
  },
  mounted() {
    this.fetchTilesDataBySlugs(
      this.dashtiles.map((i) => {
        return i.slug
      })
    )
  },
  computed: {
    ...mapGetters(['user_roles']),
    ...mapGetters('dashitems', {
      user_cards: 'userCards',
      loading: 'is_fetching',
      has_more_cards: 'hasMoreDashcard'
    }),
    mycards: {
      get() {
        return this.user_cards
      },
      set(val) {
        this.set_myCards(val)
      }
    },
    dashtiles() {
      return this.$store.getters['dashitems/tilesByAccess'](this.user_roles)
    },
    grid_count: {
      get() {
        return this.$store.getters['dashitems/grid_count']
      },
      set(val) {
        this.$store.commit('dashitems/set_gridCount', val)
      }
    },
    card_per_row() {
      if (this.smAndDown) {
        return 12
      }
      return Math.floor(12 / this.grid_count) //todo listen for mobile
    }
  },
  methods: {
    ...mapActions('dashitems', ['fetchTilesDataBySlugs']),
    ...mapMutations('dashitems', ['init_userCards', 'set_myCards']),
    handleDelete(card) {
      this.activeCard = card
      this.deleteDialog = true
    },
    confirmedDelete() {
      this.$store
        .dispatch('dashitems/removeSelectedCard', this.activeCard.slug)
        .then(() => {
          this.activeCard = null
          this.deleteDialog = false
          this.$store.commit('dashitems/init_forSelection', this.user_roles)
        })
    },
    openCardSidebar() {
      this.$store.commit('app/set_cardSidebar', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.btn-grid-count-toggle {
  border-radius: 15px !important;
  .v-btn {
    height: 40px !important;
  }
}
</style>
