<template>
  <Dashcard
    :card="card"
    :loading="globalTimerRequesting"
    @expand-card="expand"
    @delete-card="$emit('delete-card', card)"
    @click:tab="activeTab = $event"
  >
    <template v-slot:today>
      <CustomClock v-model="isRunning" :time-string="today"></CustomClock>
    </template>
    <template v-slot:week>
      <CustomClock v-model="isRunning" :time-string="week"></CustomClock>
    </template>
    <template v-slot:month>
      <CustomClock v-model="isRunning" :time-string="month"></CustomClock>
    </template>
    <!-- <template v-slot:above-image>
      <h5 class="caption font-weight-bold text-center primary--text">
        Total Time
      </h5>
      <h2 class="font-weight-bold text-center primary--text" style="font-size: 25px">
        {{ total_time }}
      </h2>
    </template> -->
  </Dashcard>
</template>

<script>
import Dashcard from '../components/Dashcard.vue'
import CustomClock from '../components/CustomClock.vue'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'TimerCard',
  props: {
    card: { type: Object }
  },
  components: {
    Dashcard,
    CustomClock
  },
  data: () => ({
    activeTab: 'today',
    total_time: '00:00:00'
  }),
  created() {
    this.getUserTimerStats()
  },
  computed: {
    ...mapGetters('timers', ['globalTimerRequesting']),
    isRunning: {
      get() {
        return this.$store.getters['timers/globalTimerIsRunning']
      },
      set(val) {
        this.$store.commit('timers/setGlobalTimerIsRunning', val)
      }
    },
    today: {
      get() {
        return this.$store.getters['timers/todayTimers']
      },
      set(val) {
        this.$store.commit('timers/setTodayTimers', val)
      }
    },
    week: {
      get() {
        return this.$store.getters['timers/weekTimers']
      },
      set(val) {
        this.$store.commit('timers/setWeekTimers', val)
      }
    },
    month: {
      get() {
        return this.$store.getters['timers/monthTimers']
      },
      set(val) {
        this.$store.commit('timers/setMonthTimers', val)
      }
    }
  },
  methods: {
    ...mapActions('timers', ['getUserTimerStats']),
    expand() {
      this.$router.push({ name: 'expanded-timers' })
    }
  }
}
</script>

<style lang="scss" scoped>
.timers-card {
  .heading-1 {
    font-size: 50px;
  }
}
</style>
