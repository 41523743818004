<template>
  <Dashcard
    :card="card"
    :loading="loading"
    @expand-card="expand"
    @change-tab="handleChangeTab"
    @delete-card="$emit('delete-card', card)"
  >
    <template v-slot:today>
      <v-card flat tile v-if="statistics.today.length">
        <v-carousel
          height="100%"
          hide-delimiters
          :show-arrows="statistics.today.length > 1"
          show-arrows-on-hover
        >
          <v-carousel-item v-for="item in statistics.today" :key="item.id">
            <v-card flat color="transparent" class="px-4">
              <v-row class="flex-column" dense align="center" justify="start">
                <h2 class="subtitle-1 fw-700 primary--text">
                  {{ item.title | ucwords }}
                </h2>
                <h6 class="fw-900 fs-20 primary--text">
                  {{ item.alarm_on | format('LLL') }}
                </h6>
                <span
                  v-if="item.repeat != 'none'"
                  class="fw-700 fs-15 primary--text"
                >
                  Repeat {{ item.repeat }}
                </span>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center mx-auto primary--text">No alarm today</h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card flat tile v-if="statistics.week.length">
        <v-carousel
          height="100%"
          hide-delimiters
          :show-arrows="statistics.week.length > 1"
          show-arrows-on-hover
        >
          <v-carousel-item v-for="item in statistics.week" :key="item.id">
            <v-card flat color="transparent" class="px-4">
              <v-row class="flex-column" dense align="center" justify="start">
                <h2 class="subtitle-1 fw-700 primary--text">
                  {{ item.title | ucwords }}
                </h2>
                <h6 class="fw-900 fs-20 primary--text">
                  {{ item.alarm_on | format('LLL') }}
                </h6>
                <span
                  v-if="item.repeat != 'none'"
                  class="fw-700 fs-15 primary--text"
                >
                  Repeat {{ item.repeat }}
                </span>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center mx-auto primary--text">
          No alarm for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card flat tile v-if="statistics.month.length">
        <v-carousel
          height="100%"
          hide-delimiters
          :show-arrows="statistics.month.length > 1"
          show-arrows-on-hover
        >
          <v-carousel-item v-for="item in statistics.month" :key="item.id">
            <v-card flat color="transparent" class="px-4">
              <v-row class="flex-column" dense align="center" justify="start">
                <h2 class="subtitle-1 fw-700 primary--text">
                  {{ item.title | ucwords }}
                </h2>
                <h6 class="fw-900 fs-20 primary--text">
                  {{ item.alarm_on | format('LLL') }}
                </h6>
                <span
                  v-if="item.repeat != 'none'"
                  class="fw-700 fs-15 primary--text"
                >
                  Repeat {{ item.repeat }}
                </span>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center mx-auto primary--text">
          No alarm for this month
        </h3>
      </v-card>
    </template>
    <template v-slot:above-image>
      <h5 class="caption fw-700 text-center primary--text">
        Alarms for {{ activeTab }}
      </h5>
      <h2 class="fw-700 text-center primary--text">
        {{ count }}
      </h2>
    </template>
  </Dashcard>
</template>

<script>
import Dashcard from '../components/Dashcard.vue'
import request from '@/services/axios_instance'
export default {
  name: 'AlarmCard',
  props: {
    card: { type: Object }
  },
  components: {
    Dashcard
  },
  data: () => ({
    loading: false,
    statistics: {
      today_count: 0,
      week_count: 0,
      month_count: 0,
      today: [],
      week: [],
      month: []
    },
    activeTab: 'month'
  }),
  created() {
    this.getData()
  },
  computed: {
    count() {
      return this.activeTab === 'today'
        ? this.statistics.today_count
        : this.activeTab === 'week'
        ? this.statistics.week_count
        : this.statistics.month_count
    }
  },
  methods: {
    getData() {
      this.loading = true
      request
        .get(`api/dashboard-contents/alarms`)
        .then(({ data }) => {
          this.statistics = data
        })
        .finally(() => (this.loading = false))
    },
    handleChangeTab(tab) {
      this.activeTab = tab
    },
    expand() {
      this.$router.push({ name: 'expanded-alarms' })
    }
  }
}
</script>

<style lang="less" scoped></style>
