<template>
  <Dashcard
    :loading="loading"
    :card="card"
    @delete-card="$emit('delete-card', card)"
    @expand-card="expand"
  >
    <template v-slot:today>
      <v-card v-if="stats.today.length" flat height="150">
        <v-card-text class="px-1">
          <CustomTaskSlider :items="stats.today"></CustomTaskSlider>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="150" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No updated or created task today
        </h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card v-if="stats.week.length" flat height="150">
        <v-card-text class="px-1">
          <CustomTaskSlider :items="stats.week"></CustomTaskSlider>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="150" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No updated or created task for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card v-if="stats.month.length" flat height="150">
        <v-card-text class="px-1 d-flex align-center justify-center">
          <CustomTaskSlider :items="stats.month"></CustomTaskSlider>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="150" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No updated or created task for this month
        </h3>
      </v-card>
    </template> 
  </Dashcard>
</template>

<script>
import CustomTaskSlider from "../components/CustomTaskSlider.vue";
import Dashcard from "../components/Dashcard.vue";
import request from "@/services/axios_instance";
export default {
  name: "TaskCard",
  props: {
    card: { type: Object },
  },
  components: {
    Dashcard,
    CustomTaskSlider,
  },
  data: () => ({
    loading: false,
    stats: {
      today: [],
      week: [],
      month: [],
    },
  }),
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      request
        .get(`api/dashboard-contents/tasks`)
        .then(({ data }) => {
          this.stats = data;
        })
        .finally(() => (this.loading = false));
    },
    expand() {
      this.$router.push({ name: "expanded-tasks" });
    },
  },
};
</script>

<style>
</style>