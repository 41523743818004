<template>
  <Dashcard
    :card="card"
    :loading="loading"
    @delete-card="$emit('delete-card', card)"
    @expand-card="expand"
  >
    <template v-slot:today>
      <v-card flat v-if="stats.today.active || stats.today.inactive">
        <v-card-text class="d-flex align-items-center justify-content-center">
          <h1
            class="
              primary--text
              text-center
              fs-30 font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.today.active }}
            <span class="caption d-block mt-3">Active</span>
          </h1>
          <apexchart
            height="140"
            width="130"
            type="donut"
            :options="chartOptions"
            :series="today_series"
          ></apexchart>
          <h1
            class="
              primary--text
              text-center
              fs-30 font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.today.inactive }}
            <span class="caption d-block mt-3">Inactive</span>
          </h1>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No new client today
        </h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card flat v-if="stats.week.active || stats.week.inactive">
        <v-card-text class="d-flex align-items-center justify-content-center">
          <h1
            class="
              primary--text
              text-center
              fs-30 font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.week.active }}
            <span class="caption d-block mt-3">Active</span>
          </h1>
          <apexchart
            height="140"
            width="130"
            type="donut"
            :options="chartOptions"
            :series="week_series"
          ></apexchart>
          <h1
            class="
              primary--text
              text-center
              fs-30 font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.week.inactive }}
            <span class="caption d-block mt-3">Inactive</span>
          </h1>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No new client this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card flat v-if="stats.month.active || stats.month.inactive">
        <v-card-text class="d-flex align-items-center justify-content-center">
          <h1
            class="
              primary--text
              text-center
              fs-30 font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.month.active }}
            <span class="caption d-block mt-3">Active</span>
          </h1>
          <apexchart
            height="140"
            width="130"
            type="donut"
            :options="chartOptions"
            :series="month_series"
          ></apexchart>
          <h1
            class="
              primary--text
              text-center
              fs-30 font-weight-bold
              align-self-center
              mx-auto
            "
          >
            {{ stats.month.inactive }}
            <span class="caption d-block mt-3">Inactive</span>
          </h1>
        </v-card-text>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No new client for this month
        </h3>
      </v-card>
    </template>
    <template v-slot:above-image>
      <h5 class="caption font-weight-bold text-center primary--text">
        Total Clients
      </h5>
      <h2 class="font-weight-bold text-center primary--text">
        {{ stats.total_clients }}
      </h2>
    </template>
  </Dashcard>
</template>

<script>
import Dashcard from "../components/Dashcard.vue";
import ApexCharts from "apexcharts";
import request from "@/services/axios_instance";
import { mapGetters } from "vuex";
export default {
  name: "ClientCard",
  props: {
    card: { type: Object },
  },
  components: {
    Dashcard,
    ApexCharts,
  },
  data: () => ({
    loading: false,
    chartOptions: {
      labels: ["Active", "Inactive"],
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      colors: ["#009788", "#2196f3"],
      plotOptions: {
        pie: {
          donut: {
            label: {
              show: true,
            },
            size: "65%",
          },
        },
      },
    },
    stats: {
      today: {
        active: 0,
        inactive: 0,
      },
      week: {
        active: 0,
        inactive: 0,
      },
      month: {
        active: 0,
        inactive: 0,
      },
      total_clients: 0,
    },
  }),
  computed: {
    ...mapGetters("dashitems", ["grid_count"]),
    today_series() {
      return [this.stats.today.active, this.stats.today.inactive];
    },
    week_series() {
      return [this.stats.week.active, this.stats.week.inactive];
    },
    month_series() {
      return [this.stats.month.active, this.stats.month.inactive];
    },
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.loading = true;
      request
        .get(`api/dashboard-contents/clients`)
        .then(({ data }) => {
          this.stats = data;
        })
        .finally(() => (this.loading = false));
    },
    expand() {
      this.$router.push({ name: "expanded-clients" });
    },
  },
};
</script>

<style>
</style>