<template>
  <Dashcard
    :card="card"
    @expand-card="expand"
    :loading="loading"
    @change-tab="handleChangeTab"
    @delete-card="$emit('delete-card', card)"
  >
    <template v-slot:today>
      <v-card flat tile v-if="statistics.today.length">
        <v-carousel
          height="100%"
          hide-delimiters
          :show-arrows="statistics.today.length > 1"
          show-arrows-on-hover
        >
          <v-carousel-item v-for="item in statistics.today" :key="item.id">
            <v-card flat color="transparent">
              <v-row dense no-gutters>
                <v-col cols="4" class="d-flex borderous">
                  <div class="align-self-center mx-auto">
                    <h3 class="primary--text text-center font-weight-bold">
                      {{ item.start | format('ddd') }}
                    </h3>
                    <br />
                    <h1 class="primary--text text-center font-weight-bold">
                      {{ item.start | format('D') }}
                    </h1>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-row no-gutters dense>
                    <h2
                      class="
                        d-block
                        col-md-12
                        subtitle-1
                        font-weight-bold
                        primary--text
                      "
                    >
                      {{ item.title | ucwords }}
                    </h2>
                    <h6
                      class="
                        d-block
                        caption
                        col-md-12
                        font-weight-bold
                        primary--text
                      "
                    >
                      {{ item.event_duration }}
                    </h6>
                  </v-row>
                  <v-row dense no-gutters>
                    <Avatars
                      class="mr-auto"
                      :items="item.users"
                      :count="4"
                    ></Avatars>
                    <v-icon color="primary">mdi-pencil</v-icon>
                    <v-icon color="primary">mdi-delete</v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center mx-auto primary--text">No events today</h3>
      </v-card>
    </template>
    <template v-slot:week>
      <v-card flat tile v-if="statistics.week.length">
        <v-carousel
          height="100%"
          hide-delimiters
          show-arrows-on-hover
          :show-arrows="statistics.week.length > 1"
        >
          <v-carousel-item v-for="item in statistics.week" :key="item.id">
            <v-card flat color="transparent">
              <v-row dense no-gutters>
                <v-col cols="4" class="d-flex borderous">
                  <div class="align-self-center mx-auto">
                    <h3 class="primary--text text-center font-weight-bold">
                      {{ item.start | format('ddd') }}
                    </h3>
                    <br />
                    <h1 class="primary--text text-center font-weight-bold">
                      {{ item.start | format('D') }}
                    </h1>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-row no-gutters dense>
                    <h2
                      class="
                        d-block
                        col-md-12
                        subtitle-1
                        font-weight-bold
                        primary--text
                      "
                    >
                      {{ item.title | ucwords }}
                    </h2>
                    <h6
                      class="
                        d-block
                        caption
                        col-md-12
                        font-weight-bold
                        primary--text
                      "
                    >
                      {{ item.event_duration }}
                    </h6>
                  </v-row>
                  <v-row dense no-gutters>
                    <Avatars
                      class="mr-auto"
                      :items="item.users"
                      :count="4"
                    ></Avatars>
                    <v-icon color="primary">mdi-pencil</v-icon>
                    <v-icon color="primary">mdi-delete</v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No events for this week
        </h3>
      </v-card>
    </template>
    <template v-slot:month>
      <v-card flat tile v-if="statistics.month.length">
        <v-carousel
          height="100%"
          hide-delimiters
          :show-arrows="statistics.month.length > 1"
          show-arrows-on-hover
        >
          <v-carousel-item v-for="item in statistics.month" :key="item.id">
            <v-card flat color="transparent">
              <v-row dense no-gutters>
                <v-col cols="4" class="d-flex borderous">
                  <div class="align-self-center mx-auto">
                    <h3 class="primary--text text-center font-weight-bold">
                      {{ item.start | format('ddd') }}
                    </h3>
                    <br />
                    <h1 class="primary--text text-center font-weight-bold">
                      {{ item.start | format('D') }}
                    </h1>
                  </div>
                </v-col>
                <v-col cols="8">
                  <v-row no-gutters dense>
                    <h2
                      class="
                        d-block
                        col-md-12
                        subtitle-1
                        font-weight-bold
                        primary--text
                      "
                    >
                      {{ item.title | ucwords }}
                    </h2>
                    <h6
                      class="
                        d-block
                        caption
                        col-md-12
                        font-weight-bold
                        primary--text
                      "
                    >
                      {{ item.event_duration }}
                    </h6>
                  </v-row>
                  <v-row dense no-gutters>
                    <Avatars
                      class="mr-auto"
                      :items="item.users"
                      :count="4"
                    ></Avatars>
                    <v-icon color="primary">mdi-pencil</v-icon>
                    <v-icon color="primary">mdi-delete</v-icon>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-carousel-item>
        </v-carousel>
      </v-card>
      <v-card class="d-flex" min-height="100" v-else flat tile>
        <h3 class="align-self-center text-center mx-auto primary--text">
          No events for this month
        </h3>
      </v-card>
    </template>
    <template v-slot:above-image>
      <h5 class="caption font-weight-bold text-center primary--text">
        Events for {{ activeTab }}
      </h5>
      <h2
        class="font-weight-bold text-center primary--text"
        style="font-size: 25px"
      >
        {{ count }}
      </h2>
    </template>
  </Dashcard>
</template>

<script>
import Dashcard from '../components/Dashcard.vue'
import request from '@/services/axios_instance'
export default {
  name: 'CalendarCard',
  props: {
    card: { type: Object }
  },
  components: {
    Dashcard
  },
  data: () => ({
    loading: false,
    statistics: {
      today_count: 0,
      week_count: 0,
      month_count: 0,
      today: [],
      week: [],
      month: []
    },
    activeTab: 'month'
  }),
  created() {
    this.getData()
  },
  computed: {
    count() {
      return this.activeTab === 'today'
        ? this.statistics.today_count
        : this.activeTab === 'week'
        ? this.statistics.week_count
        : this.statistics.month_count
    }
  },
  methods: {
    getData() {
      this.loading = true
      request
        .get(`api/dashboard-contents/calendar`)
        .then(({ data }) => {
          this.statistics = data
        })
        .finally(() => (this.loading = false))
    },
    handleChangeTab(tab) {
      this.activeTab = tab
    },
    expand() {
      this.$router.push({ name: 'expanded-calendar' })
    }
  }
}
</script>

<style lang="scss" scoped>
.borderous {
  &::after {
    content: '';
    height: 100%;
    width: 2px;
    background: #e0e0e0;
    margin-left: 20px;
    margin-right: 20px;
  }
}
</style>
