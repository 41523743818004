<template>
  <v-hover>
    <template v-slot="{ hover }">
      <v-card
        outlined
        height="40"
        :dense="!hover"
        elevation="2"
        class="dashtile fill-height"
        @click="$emit('navigate', payload)"
      >
        <v-row
          dense
          class="fill-height pt-2 d-flex justify-space-around align-center"
        >
          <div class="left caption primary--text pl-2">
            <v-icon color="primary" small left> {{ icon }} </v-icon>
            {{ label | ucwords }} :
          </div>
          <div class="right caption primary--text pr-5">{{ value }}</div>
        </v-row>
      </v-card>
    </template>
  </v-hover>
</template>

<script>
export default {
  name: "Dashtile",
  props: {
    label: { type: String, default: "Sample Label" },
    value: { type: [String, Number], default: 0 },
    icon: { type: String, default: "mdi-alert-circle" },
  },
  computed: {
    payload() {
      return {
        label: this.label,
        value: this.value,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
 
.dashtile {
  cursor: pointer;
  border-radius: 15px;
  .left {
    font-weight: bolder;
    font-size: 12px;
  }
  .right {
    font-weight: bold;
    color: #223a6b;
    float: right;
  }
}
</style>